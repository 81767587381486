<template>
  <v-expansion-panel v-if="content.MenuContentID">
    <v-expansion-panel-header>{{ $t('dtouch.dishOrders.extras.title', locale)}}</v-expansion-panel-header>
    <v-expansion-panel-content class="extras-main"
      :style="{ padding: !isDview ? '0 20px 20px 20px' : null }"
    >
      <div style="padding-top: 20px;">
        <translate
          type="text"
          v-model="labelExtra"
          :languages="languages || Languages"
          :label="$t('dtouch.dishOrders.labelExtra', locale)"
          @input="handleChangeIsModified"
        />
        <v-switch
          v-model="onlyOne"
          :label="$t('dtouch.dishOrders.onlyOne', locale)"
          @change="handleChangeIsModified"
          hide-details
        />
      </div>
      <list
        :content="content"
        :locale="locale"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import List from './List'
import { mapState } from 'vuex'
import Translate from '@/components/Translate'
export default {
  components: {
    List,
    Translate,
  },
  props: {
    locale: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    labelExtra: { es: '' },
    onlyOne: false,
  }),
  computed: {
    ...mapState('app',['languages', 'Languages', 'isDview']),
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.content.FoodLabelExtras) this.labelExtra = JSON.parse(this.content.FoodLabelExtras)
      if (this.content.FoodOnlyOne) this.onlyOne = this.content.FoodOnlyOne
    },
    handleChangeIsModified () {
      this.onChange(this.labelExtra, this.onlyOne)
    },
  },
}
</script>

