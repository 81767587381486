<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <translate
        type="text"
        v-model="content.FoodName"
        :languages="languages"
        :label="$t('dtouch.food.name')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <translate
        type="textArea"
        v-model="content.FoodIngredients"
        :languages="languages"
        :label="$t('dtouch.food.ingredients')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs4>
      <v-text-field
        v-model="content.FoodPrice"
        :label="$t('dtouch.food.price')"
        outlined
        @input="handleChangeIsModified"
        hide-details
      />
    </v-flex>
    <v-flex xs8 justify-end style="padding-top: 5px;text-align: right;">
      <v-btn-toggle
        v-model="auxIndication"
        multiple
      >
        <v-btn>
          <v-icon color="#a9c366">mdi-leaf</v-icon>
        </v-btn>

        <v-btn>
          <v-icon color="#436f45">mdi-leaf</v-icon>
        </v-btn>

        <v-btn>
          <v-icon color="red">mdi-new-box</v-icon>
        </v-btn>

        <v-btn>
          <v-icon color="orange">mdi-star-box</v-icon>
        </v-btn>

        <v-btn>
          <v-icon color="red">mdi-snail</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <translate
        type="text"
        v-model="content.FoodNote"
        :languages="languages"
        :label="$t('dtouch.food.note')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <v-expansion-panels focusable>
        <food-drink-extras-form 
          v-if="showExtras"
          :content="content"
          :locale="locale"
          :onChange="handleChangeExtraConfigLocal"
        />
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('dtouch.food.allergens', locale)}}</v-expansion-panel-header>
          <v-expansion-panel-content class="allergens-main">
            <div
              v-for="(allergen) in allergens"
              :key="allergen.ID"  
              class="allergens-item" 
            >
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <table v-on="on">
                    <tr>
                      <td style="vertical-align : middle;">
                        <v-checkbox
                          v-model="content.FoodAllergens"
                          :value="allergen.ID"
                          @change="handleChangeIsModified"
                        />
                      </td>
                      <td style="vertical-align : middle;">
                        <img
                          :src="`https://www.dtouch.es/assets/allergens/${allergen.Image}.png`"
                          width="50px"
                          heigth="auto"
                          alt="logo"
                          style="margin: 5px;"
                        >
                      </td>
                    </tr>
                  </table>
                </template>
                <span>{{ getTranslation(allergen.Name, locale) }}</span>
              </v-tooltip>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('dtouch.food.image', locale) }}</v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 25px 0 10px 0;">
            <select-image 
              :image="content.FoodImage"
              :onChange="handleChangeImage"
              :locale="locale"
              :imageWidth="600"
              :imageHeight="600"
              :aspectRatio="1/1"
              :folder="workspaceID"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import Translate from '@/components/Translate'
//import SelectImageOnSave from '@/components/SelectImageOnSave'
import SelectImage from '@/components/selectImage/Index'
import FoodDrinkExtrasForm from '../../dishOrders/foodDrinkExtrasForm/Index'
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  name: 'Form27Food',
  components: {
    Translate,
    SelectImage,
    //SelectImageOnSave,
    FoodDrinkExtrasForm,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    content: null,
    auxIndication: [],
    allergens: null,
    showExtras: false,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.config.Languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    }
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
    auxIndication (v) {
      this.editedItem.FoodVegan = v.indexOf(0) >= 0
      this.editedItem.FoodVegetarian = v.indexOf(1) >= 0
      this.editedItem.FoodNew = v.indexOf(2) >= 0
      this.editedItem.FoodRecommended = v.indexOf(3) >= 0
      this.editedItem.FoodSlowFood = v.indexOf(4) >= 0

      this.handleChangeIsModified()
    },
  },
  mounted () {
    this.getAllergens()
    this.prepareContent()
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    getAllergens () {
      api.getItem ('dtouch', `v1/public/workspaces/`, this.workspaceID.concat('/allergens'))
        .then(response => {
          this.allergens = response.data
        })
    },
    checkJSON (v) {
      return typeof v == 'object' ? v : JSON.parse(v)
    },
    checkShowExtras () {
      api.getItem ('dtouch', `v1/public/workspaces/`, this.workspaceID.concat('/show-extras-form'))
        .then(response => {
          this.showExtras = response && response.IncludeExtras ? true : false
        })
    },
    prepareContent () {
      this.checkShowExtras()
      this.auxIndication =  []
      if (this.editedItem.FoodVegan) this.auxIndication.push(0)
      if (this.editedItem.FoodVegetarian) this.auxIndication.push(1)
      if (this.editedItem.FoodNew) this.auxIndication.push(2)
      if (this.editedItem.FoodRecommended) this.auxIndication.push(3)
      if (this.editedItem.FoodSlowFood) this.auxIndication.push(4)

      const aux = this.editedItem
      aux.FoodName = this.checkJSON(aux.FoodName)
      aux.FoodIngredients = this.checkJSON(aux.FoodIngredients)
      aux.FoodNote = this.checkJSON(aux.FoodNote)
      aux.FoodAllergens = this.checkJSON(aux.FoodAllergens)
      this.content = aux
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
    handleChangeImage (v) {
      if (v) {
        //if (!utils.checkIsBase64(this.content.FoodImage)) v.urlImageToDelete = this.content.FoodImage
        this.content.FoodImage = v.base64
        this.content.newImage = v //'https://social.infoten.es/Fichas/PublishingImages/capture_03112013_152110.jpg'
        this.handleChangeIsModified()
      }
    },
    handleChangeExtraConfigLocal (labelExtra, onlyOne) {
      this.content.FoodLabelExtras = labelExtra
      this.content.FoodOnlyOne = onlyOne
      this.handleChangeIsModified()
    },
  },
}
</script>
<style scoped>
  .allergens-main {
    width: 100%;
  }
  .allergens-item {
    float: left;
    padding-right: 10px;
  }
</style>

